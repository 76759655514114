@mixin light-timeline-theme($theme) {
  .timeline-container {
    .vis-time-axis .vis-text,
    .vis-labelset .vis-label {
      color: rgba(0, 0, 0, 0.38) !important;
    }
  }
}

@mixin dark-timeline-theme($theme) {
  .timeline-container {
    .vis-time-axis .vis-text,
    .vis-labelset .vis-label {
      color: rgba(255, 255, 255, 0.5) !important;
    }
  }
}
