@use '@angular/material' as mat;

@use '../themes/light-theme';
@use '../themes/dark-theme';
@use '../utils/colors';
@use '../utils/typography';

@mixin light-table-theme($theme) {
  @include default-table-theme($theme);

  .table-container {
    .table-content-style {
      color: #4c4e4e;
    }
  }
}

@mixin dark-table-theme($theme) {
  @include default-table-theme($theme);

  .table-container {
    .table-content-style {
      color: white;
    }
  }
}

@mixin default-table-theme($theme) {
  .table-container {
    .mat-mdc-table-style,
    .mat-header-style,
    .mat-mdc-header-cell {
      border-color: #4f899b;
      border-bottom-width: 1px;
      color: #4f899b;
      font-size: 15px;
      font-weight: bold;
    }

    .table-content-style {
      font-weight: 100;
    }

    .mat-mdc-cell {
      color: #4f899b;
      font-size: 15px;
    }

    .mat-column-audit_status {
      text-transform: uppercase;
      font-weight: bold;
    }

    .mat-mdc-row {
      &.clickable {
        cursor: pointer;
      }

      &.expanded-detail-row {
        min-height: 0;

        .expanded-detail {
          overflow: hidden;
          display: flex;
          width: 100%;
        }
      }
    }

    .mat-column-select {
      justify-content: center;
      overflow: initial;
    }

    border-radius: 4px;
    max-width: 100%;
    position: relative;

    > .table-loader {
      align-items: center;
      background: rgba(0, 0, 0, 0.25);
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 9999;
    }

    > .table {
      overflow: auto;

      table.mat-mdc-table {
        width: 100%;

        tr.mat-mdc-row {
          height: 55px;
        }

        th.mat-mdc-header-cell {
          border-color: #4f899b;
          border-bottom-width: 1px;
          color: #4f899b;
          font-size: 15px;
          font-weight: bold;
        }

        td.no-data {
          text-align: center;
        }
      }
    }
  }
}
