@use '@angular/material' as mat;
@use '../utils/colors';
@use '../themes/light-theme';
@use '../themes/dark-theme';

@mixin light-cube-role-entity-theme($theme) {
  @include default-cube-role-entity-theme($theme);
/* 
  .selected-item {
    background: mat.get-color-from-palette($rina-darkgray, 900);
  }

  // .mat-chip-selected
  .mat-mcd-chip.mat-mdc-standard-chip.mat-primary {
    background: mat.get-color-from-palette($rina-darkgray, 800);
    color: whitesmoke;
  }

  .panel-style {
    background: mat.get-color-from-palette($rina-darkgray, 800);
  } */
}

@mixin dark-cube-role-entity-theme($theme) {
  @include default-cube-role-entity-theme($theme);


}

@mixin default-cube-role-entity-theme($theme) {

}
