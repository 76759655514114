@use '@angular/material' as mat;
@use '../utils/colors';
@use '../utils/flexbox';

@mixin light-homepage-theme($theme) {
  @include default-homepage-theme();
}

@mixin dark-homepage-theme($theme) {
  @include default-homepage-theme();
}

@mixin default-homepage-theme() {
  .dias-home {
    height: 100%;
    padding: 2.5rem;
    @include flexbox.flexbox();

    .home-container {
      p {
        line-height: 1.4rem;
        padding-bottom: 2.5rem;
      }

      .external-link {
        color: #6085ff;
      }
    }
  }
}
