@use "@angular/material" as mat;
@use "../utils/colors";
@use "../themes/light-theme";
@use "../themes/dark-theme";

@mixin light-cube-developers-theme($theme) {
  .cube-developers-element {
    .selected-item {
      border-bottom: 1px solid black;
    }
  }
}

@mixin dark-cube-developers-theme($theme) {
  .cube-developers-element {
    .selected-item {
      border-bottom: 1px solid white;
    }
  }
}
